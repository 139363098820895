<template>
<div class="flex justify-center w-full owf-h" v-loading="loading" style="width: 100%">
    <div class="register mw-1920">
        <div class="register-item register-left">
            <div class="register-title">
                <h1>Реєстрація</h1>
                <p>Вітаємо на нашому ресурсі! Проведіть реєстрацію:</p>
            </div>
            <div class="flex justify-center">

                <div class="register-card">
                    <div class="register-card-header">
                        <div class="register-card-header-item" :class="userType==4?'active':''" @click="userType=4; step=1">
                            <p> Фізичне лице </p>
                        </div>
                        <div class="register-card-header-item" :class="userType==3?'active':''" @click="userType=3; step=1">
                            <p> Юридичне лице </p>
                        </div>
                    </div>
                    <div class="reg-forms" v-show="userType==4">
                        <el-form ref="pcsicalForm1" :model="User" :rules="UserRules1" v-show="step==1" v-loading="checkloading">
                            <div class="register-card-form">
                                <div class="register-card-input">
                                    <el-form-item prop="email" :class="mailError?'is-error' :''">
                                        <el-input placeholder="Електронна пошта*" v-model="User.email"></el-input>
                                        <p class="el-form-item__error" v-show="mailError">Электронная почта уже занята.</p>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input" v-if="userType==4">
                                    <el-form-item prop="phone">
                                        <el-input placeholder="Номер телефону*" v-mask="'+### ## ### ## ##'" v-model="User.phone"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="password">
                                        <div class="password-input">
                                            <el-input placeholder="Пароль*" class="with-icon" v-model="User.password" :type="password_show1?'text':'password'"></el-input>
                                            <div class="show-password">
                                                <img :src="password_show1? require('@/assets/icons/openeye.svg'):require('@/assets/icons/overlineye.svg')" @click="password_show1=!password_show1" alt="">
                                            </div>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="verify_password">
                                        <div class="password-input">
                                            <el-input placeholder="Повторите пароль*" class="with-icon" v-model="User.verify_password" :type="password_show2?'text':'password'"></el-input>
                                            <div class="show-password">
                                                <img :src="password_show2? require('@/assets/icons/openeye.svg'):require('@/assets/icons/overlineye.svg')" @click="password_show2=!password_show2" alt="">
                                            </div>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="flex justify-center register-submit">
                                    <button class="button " type="button" @click="UserReg()">Дальше</button>
                                </div>
                            </div>
                        </el-form>
                        <el-form ref="pcsicalForm2" :model="User" :rules="UserRules2" v-show="step==2">
                            <div class="register-card-form">
                                <div class="legal-first-inputs">
                                    <div class="register-card-input">
                                        <el-form-item prop="f_name">
                                            <el-input placeholder="Ім’я*" v-model="User.f_name"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="register-card-input">
                                        <el-form-item prop="l_name">
                                            <el-input placeholder="Прізвище*" v-model="User.l_name"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="s_name">
                                        <el-input placeholder="По батькові*" v-model="User.s_name"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="birthdate">
                                        <el-date-picker class="w-full no-icon" format="dd/MM/yyyy" value-format="yyyy-MM-dd" type="date" v-model="User.birthdate" placeholder="Дата народження*">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="passport_serial">
                                        <el-input placeholder="Номер та серія паспорта*" v-model="User.passport_serial"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="issued_by">
                                        <el-input type="text" placeholder="Ким виданий паспорт*" v-model="User.issued_by"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="issued_date">
                                        <el-date-picker class="w-full no-icon" format="dd/MM/yyyy" value-format="yyyy-MM-dd" type="date" v-model="User.issued_date" placeholder="Дата видачі паспорта*">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="flex justify-center register-submit">
                                    <button class="button " type="button" @click="UserReg()">Дальше</button>
                                </div>
                            </div>
                        </el-form>
                        <el-form ref="pcsicalForm3" :model="User" :rules="UserRules3" v-show="step==3" v-loading="regloading">
                            <div class="register-card-form">
                                <div class="register-card-input">
                                    <el-form-item prop="identification_number">
                                        <el-input v-mask="'##########'" placeholder="Ідентифікаційний код*" v-model="User.identification_number"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="passport_serial_without_id">
                                        <el-input placeholder="Реєстраційний номер облікової картки платника податку" v-model="User.passport_serial_without_id"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="state">
                                        <el-input placeholder="По Область*" v-model="User.state"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="region">
                                        <el-input placeholder="Район*" v-model="User.region"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="city">
                                        <el-input placeholder="Город*" v-model="User.city"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="street">
                                        <el-input placeholder="Улица*" v-model="User.street"></el-input>
                                    </el-form-item>
                                </div>

                                <div class="legal-first-inputs">
                                    <div class="register-card-input">
                                        <el-form-item prop="building_number">
                                            <el-input placeholder="Номер здания*" v-model="User.building_number"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="register-card-input">
                                        <el-form-item prop="apartment_number">
                                            <el-input placeholder="Номер квартиры*" v-model="User.apartment_number"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="black-check">
                                    <blackCheck v-model="confirm" />
                                    <p @click="confirm=!confirm" style="cursor:pointer;">Соглашение с условиями   </p>
                                </div>
                                <div class="flex justify-center register-submit">
                                    <button class="button" :disabled="!confirm" type="button" :class="!confirm? 'opacity8':''" @click="UserReg()">Зареєструватися</button>
                                </div>
                            </div>
                        </el-form>
                    </div>
                    <div class="reg-forms" v-show="userType==3">
                        <el-form ref="pcsicalForm1" :model="User" :rules="UserRules1" v-show="step==1" v-loading="checkloading">
                            <div class="register-card-form">
                                <div class="register-card-input">
                                    <el-form-item prop="email" :class="mailError?'is-error' :''">
                                        <el-input placeholder="Електронна пошта*" v-model="User.email"></el-input>
                                        <p class="el-form-item__error" v-show="mailError">Электронная почта уже занята.</p>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input" >
                                    <el-form-item prop="phone">
                                        <el-input placeholder="Номер телефону*" v-if="userType==3" v-mask="'+### ## ### ## ##'" v-model="User.phone"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="password">
                                        <div class="password-input">
                                            <el-input placeholder="Пароль*" class="with-icon" v-model="User.password" :type="password_show1?'text':'password'"></el-input>
                                            <div class="show-password">
                                                <img :src="password_show1? require('@/assets/icons/openeye.svg'):require('@/assets/icons/overlineye.svg')" @click="password_show1=!password_show1" alt="">
                                            </div>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="verify_password">
                                        <div class="password-input">
                                            <el-input placeholder="Повторите пароль*" class="with-icon" v-model="User.verify_password" :type="password_show2?'text':'password'"></el-input>
                                            <div class="show-password">
                                                <img :src="password_show2? require('@/assets/icons/openeye.svg'):require('@/assets/icons/overlineye.svg')" @click="password_show2=!password_show2" alt="">
                                            </div>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="flex justify-center register-submit">
                                    <button class="button " type="button" @click="UserReg()">Дальше</button>
                                </div>
                            </div>
                        </el-form>
                        <el-form ref="pcsicalForm4" :model="User" :rules="UserRules4" v-show="step==2">
                            <div class="register-card-form">
                                <div class="register-card-input">
                                    <el-form-item prop="organization_name">
                                        <el-input placeholder="Ім'я юридичного лицяа*" v-model="User.organization_name"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="address">
                                        <el-input placeholder="Місцезнаходження юридичного лиця*" v-model="User.address"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="date_number">
                                        <el-input placeholder="Дата та номер запису*" v-model="User.date_number"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="executive_organization">
                                        <el-input type="text" placeholder="Інформація про виконуючий орган*" v-model="User.executive_organization"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="flex justify-center register-submit">
                                    <button class="button " type="button" @click="UserReg()">Дальше</button>
                                </div>
                            </div>
                        </el-form>
                        <el-form ref="pcsicalForm2" :model="User" :rules="UserRules2" v-show="step==3">
                            <div class="register-card-form">
                                <div class="legal-first-inputs">
                                    <div class="register-card-input">
                                        <el-form-item prop="f_name">
                                            <el-input placeholder="Ім’я*" v-model="User.f_name"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="register-card-input">
                                        <el-form-item prop="l_name">
                                            <el-input placeholder="Прізвище*" v-model="User.l_name"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="s_name">
                                        <el-input placeholder="По батькові*" v-model="User.s_name"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="birthdate">
                                        <el-date-picker class="w-full no-icon" format="dd/MM/yyyy" value-format="yyyy-MM-dd" type="date" v-model="User.birthdate" placeholder="Дата народження*">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="passport_serial">
                                        <el-input placeholder="Номер та серія паспорта*" v-model="User.passport_serial"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="issued_by">
                                        <el-input type="text" placeholder="Ким виданий паспорт*" v-model="User.issued_by"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="issued_date">
                                        <el-date-picker class="w-full no-icon" format="dd/MM/yyyy" value-format="yyyy-MM-dd" type="date" v-model="User.issued_date" placeholder="Дата видачі паспорта*">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="passport_serial_without_id">
                                        <el-input placeholder="Реєстраційний номер облікової картки платника податку*" v-model="User.passport_serial_without_id"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="flex justify-center register-submit">
                                    <button class="button " type="button" @click="UserReg()">Дальше</button>
                                </div>
                            </div>
                        </el-form>
                        <el-form ref="pcsicalForm5" :model="User" :rules="UserRules5" v-show="step==4">
                            <div class="register-card-form">
                                <div class="legal-first-inputs">
                                    <div class="register-card-input">
                                        <el-form-item prop="user_f_name">
                                            <el-input placeholder="Ім’я*" v-model="User.user_f_name"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="register-card-input">
                                        <el-form-item prop="user_l_name">
                                            <el-input placeholder="Прізвище*" v-model="User.user_l_name"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="user_s_name">
                                        <el-input placeholder="По батькові*" v-model="User.user_s_name"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="user_birthdate">
                                        <el-date-picker class="w-full no-icon" format="dd/MM/yyyy" value-format="yyyy-MM-dd" type="date" v-model="User.user_birthdate" placeholder="Дата народження*">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="user_address">
                                        <el-input placeholder="Країна постійного місця проживання*" v-model="User.user_address"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="register-card-input">
                                    <el-form-item prop="user_identification_number">
                                        <el-input placeholder="Ідентифікаційний код*" v-mask="'##########'"  v-model="User.user_identification_number"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="black-check">
                                    <blackCheck v-model="confirm" />
                                    <p @click="confirm=!confirm" style="cursor:pointer;">Соглашение с условиями</p>
                                </div>

                                <div class="flex justify-center register-submit">
                                    <button class="button " type="button" :disabled="!confirm" @click="UserReg()" :class="!confirm? 'opacity8':''">Зареєструватися</button>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </div>

            </div>
            <div class="register-bottom-text">
                <a href="https://avior.dbms.in/page/privacy-policy" target="_blank">
                    <p>Політика конфіденційності</p>
                </a>
            </div>

        </div>
        <div class="register-item">
            <div class="register-right">
                <div class="w-full">
                    <p>Маєте створений аккаунт?</p>
                    <div class="flex justify-center">
                        <button class="button blue" @click="$router.push('/login')">Авторизуватися</button>
                    </div>

                </div>
            </div>
            <div class="register-cover">
                <img src="@/assets/img/login-bottom.svg" class="desktop" alt="">
                <img src="@/assets/img/login-phone-bottom.svg" class="phone" alt="">
            </div>
            <div class="register-clouds desktop">
                <img src="@/assets/img/login-clouds.svg" alt="">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import blackCheck from '../../components/check-black.vue'
export default {
    components: {
        blackCheck
    },
    data() {
        let reg = /(?!^ (\d +|[a-zA-Z]+ | [~!@#$%^&*?]+ )$ )^[\w~!@#$%^&*?]{6,32}$/
        let text = /[А-Я][а-я]+|[А-Яа-яЁё]+$/
        let Num = /^[0-9]+$/
        // let vpassport=/^[А-ЯЁ]+|[A-Z]+|[0-9]+$/
        let pSernum = /(^[А-ЯЁ0-9A-Z ]+|[А-Я])+$/
        let givePassport = /[А-Я][а-я]+|[А-Яа-яЁё0-9A-Za-z- ]+$/

        var validatext = (rule, value, callback) => {
            if (!text.test(value)) {
                callback(new Error(this.$t('input_ukrain_alphabet')))
            } else {
                callback()
            }
        }
        var validaPassportSer = (rule, value, callback) => {
            if (!pSernum.test(value)) {
                callback(new Error('Вы ввели запрещенные символы   А-Я, A-Z, 0-9!'))
            } else {
                callback()
            }
        }
        var validateGivePassport = (rule, value, callback) => {
            if (!givePassport.test(value)) {
                callback(new Error('Вы ввели запрещенные символы    А-Я, A-Z, а-я, a-z, 0-9!'))
            } else {
                callback()
            }
        }
        var validateNewPwd = (rule, value, callback) => {
            if (!reg.test(value)) {
                callback(new Error(this.$t('password_len')))
            } else {
                callback()
            }
        }
        var validateComfirmPwd = (rule, value, callback) => {
            if (!reg.test(value)) {
                callback(new Error(this.$t('password_len')))
            } else if (this.User.verify_password !== this.User.password) {
                callback(new Error('Пароль подтверждения отличается от нового пароля.'))
            } else {
                callback()
            }
        }
        var validateNum = (rule, value, callback) => {
            if (!Num.test(value)) {
                callback(new Error('Вы ввели запрещенные символы   0-9!'))
            } else {
                callback()
            }
        }
        return {
            input: '',
            password_show1: false,
            password_show2: false,
            disabled: true,
            userType: 4,
            step: 1,
            confirm: false,
            loading: false,
            mailError: false,
            checkloading: false,
            regloading: false,
            User: {
                role_id: 4,
                password: '',
                verify_password: '',
                f_name: '',
                l_name: '',
                s_name: '',
                phone: '',
                birthdate: '',
                passport_serial: '',
                issued_by: '',
                issued_date: '',
                identification_number: '',
                address: '',
                street: '',
                email: "",
                email_verified_at: '',
                passport_serial_without_id: '',
                state: '',
                region: '',
                city: null,
                building_number: null,
                apartment_number: null,
                date_number: null,
                executive_organization: null,
                user_f_name: '',
                user_l_name: '',
                user_s_name: '',
                user_birthdate: '',
                user_address: '',
                user_identification_number: ''

            },
            UserRules1: {
                email: [{
                    required: true,
                    type: "email",
                    message: 'Введіть електронну адресу',
                    trigger: 'change'
                }],
                phone: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        required: true,
                        pattern: /^(?=.*[0-9])[- +()0-9]+$/,
                        message: this.$t('input_phone_req'),
                        trigger: 'blur'
                    }
                ],
                password: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }, {
                    validator: validateNewPwd,
                    trigger: 'blur'
                }],
                verify_password: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }, {
                    validator: validateComfirmPwd,
                    trigger: 'blur'
                }],
            },
            UserRules2: {
                f_name: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 40,
                        message: this.$t('min_two_sim'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                l_name: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 40,
                        message: this.$t('min_two_sim'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                s_name: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 40,
                        message: this.$t('min_two_sim'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                birthdate: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }],
                passport_serial: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        validator: validaPassportSer,
                        trigger: 'blur'
                    }
                ],
                issued_by: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        validator: validateGivePassport,
                        trigger: 'blur'
                    }
                ],
                issued_date: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }],

            },
            UserRules3: {
                identification_number: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 10,
                        max: 10,
                        message: this.$t('input_olny_ten'),
                        trigger: 'blur'
                    },
                    {
                        validator: validateNum,
                        trigger: 'blur'
                    }
                ],
                passport_serial_without_id: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        validator: validaPassportSer,
                        trigger: 'blur'
                    }
                ],
                state: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 3,
                        max: 40,
                        message: this.$t('input_min_three'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                region: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 3,
                        max: 40,
                        message: this.$t('input_min_three'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                city: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 3,
                        max: 40,
                        message: this.$t('input_min_three'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                street: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 3,
                        max: 40,
                        message: this.$t('input_min_three'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                building_number: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 3,
                        message: this.$t('input_min_one'),
                        trigger: 'blur'
                    },
                    {
                        validator: validateGivePassport,
                        trigger: 'blur'
                    }
                ],
                
            },
            UserRules4: {
                organization_name: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 80,
                        message: this.$t('input_min_two'),
                        trigger: 'blur'
                    },
                ],
                address: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 4,
                        max: 100,
                        message: this.$t('input_min_four'),
                        trigger: 'blur'
                    },
                ],
                executive_organization: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 100,
                        message: this.$t('min_two_sim'),
                        trigger: 'blur'
                    }
                ],
                date_number: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        validator: validateNum,
                        trigger: 'blur'
                    }
                ],

            },
            UserRules5: {
                user_f_name: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 40,
                        message: this.$t('min_two_sim'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                user_l_name: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 40,
                        message: this.$t('min_two_sim'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                user_s_name: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 40,
                        message: this.$t('min_two_sim'),
                        trigger: 'blur'
                    },
                    {
                        validator: validatext,
                        trigger: 'blur'
                    }
                ],
                user_birthdate: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }],
                user_address: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }],
                user_identification_number: [{
                        required: true,
                        message: this.$t('requier_input'),
                        trigger: 'blur'
                    },
                    {
                        min: 10,
                        max: 10,
                        message: this.$t('input_olny_ten'),
                        trigger: 'blur'
                    },
                    {
                        validator: validateNum,
                        trigger: 'blur'
                    }
                ],
            }
        }
    },
    watch: {
        'User.phone'(val){
            if(val.length==3){
                console.log("AA")
            }
        },
        'PysicalUser.email'() {
            this.erorrsData.email = false
        },
        'LegalUser.email'() {
            this.erorrsData.email = false
        },
        'PysicalUser.verify_password'(val) {
            if (this.PysicalUser.password == val || val == '') {
                this.errors.password2 = false
            } else {
                this.errors.password2 = true
            }
        },
        'LegalUser.verify_password'(val) {
            if (this.LegalUser.password == val || val == '') {
                this.errors.password1 = false
            } else {
                this.errors.password1 = true
            }
        },
        'User.email'() {
            this.mailError = false
        },
        'userType'() {
            this.step = 1; 
            this.$refs.pcsicalForm1.resetFields();
            this.$refs.pcsicalForm2.resetFields();
            this.$refs.pcsicalForm3.resetFields();
            this.$refs.pcsicalForm4.resetFields();
            this.$refs.pcsicalForm5.resetFields();

        }

    },
    methods: {
        UserReg() {
            if (this.userType == 4) {
                this.UserReg1();
                console.log(this.userType)
            }
            if (this.userType == 3) {
                this.UserReg2();
                console.log(this.userType)
            }
        },
        UserReg1() {
            if (this.step == 3) {
                this.$refs.pcsicalForm3.validate((valid) => {
                    if (valid) {
                        this.regloading = true
                        this.User.role_id = this.userType
                        this.axios.post('/register', this.User).then(res => {
                                localStorage.token = res.data.data.token
                                this.axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.data.token}`;
                                this.$store.commit('get_me', {
                                    payload: res.data.data.user
                                })
                                this.$notify({
                                    title: 'Авторизація',
                                    message: 'Ви успішно зареєструвались',
                                    type: 'success'
                                });
                                if (localStorage.month !== 'null' && localStorage.count !== 'null' && localStorage.count && localStorage.month) {
                                    if (localStorage.type == 'credit') {
                                        this.$router.push(`/credit?amount=${localStorage.count}&month=${localStorage.month}`)
                                    } else {
                                        this.$router.push(`/leasing/technique/get/${localStorage.id}?amount=${localStorage.count}&month=${localStorage.month}`)
                                    }

                                    localStorage.month = null
                                    localStorage.count = null
                                    localStorage.id = null
                                } else {
                                    this.$router.push('/')
                                }

                            })
                            .catch(error => {
                                this.erorrsData = error.response.data.message
                                let ERRORS = error.response.data.errors;
                                let keys = Object.keys(ERRORS);
                                let error_msg = ERRORS[keys[0]];
                                this.$notify.error({
                                    title: 'Произошла ошибка',
                                    message: error_msg ? error_msg[0] : "Произошла ошибка",
                                });

                            })
                            .finally(() => {
                                this.regloading = false
                            })

                    }
                })
            }
            if (this.step == 2) {
                this.$refs.pcsicalForm2.validate((valid) => {
                    if (valid) {
                        this.step = 3
                    }
                })
            }
            if (this.step == 1) {
                this.$refs.pcsicalForm1.validate((valid) => {
                    if (valid) {
                        var data = {
                            email: this.User.email
                        }
                        this.checkloading = true
                        this.axios.post('/check-user', data)
                            .then(res => {
                                if (res.data.success) {
                                    this.step = 2
                                } else {
                                    this.mailError = true
                                }
                            })
                            .catch(() => {
                                return 2
                            })
                            .finally(() => {
                                this.checkloading = false
                            })
                    }
                })
            }

        },
        UserReg2() {
            if (this.step == 4) {
                this.$refs.pcsicalForm5.validate((valid) => {
                    if (valid) {
                        this.regloading = true
                        this.User.role_id = this.userType
                        this.axios.post('/register', this.User).then(res => {
                                localStorage.token = res.data.data.token
                                this.axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.data.token}`;
                                this.$router.push({
                                    name: 'home'
                                })
                                this.$store.commit('get_me', {
                                    payload: res.data.data.user
                                })
                                this.$notify({
                                    title: 'Авторизація',
                                    message: 'Ви успішно зареєструвались',
                                    type: 'success'
                                });

                            })
                            .catch(error => {
                                this.erorrsData = error.response.data.message
                                let ERRORS = error.response.data.errors;
                                let keys = Object.keys(ERRORS);
                                let error_msg = ERRORS[keys[0]];
                                this.$notify.error({
                                    title: 'Произошла ошибка',
                                    message: error_msg ? error_msg[0] : "Произошла ошибка",
                                });

                            })
                            .finally(() => {
                                this.regloading = false
                            })

                    }
                })
            }
            if (this.step == 3) {
                this.$refs.pcsicalForm2.validate((valid) => {
                    if (valid) {
                        this.step = 4;
                    }
                })
            }
            if (this.step == 2) {
                this.$refs.pcsicalForm4.validate((valid) => {
                    if (valid) {
                        this.step = 3;
                    }
                })
            }
            if (this.step == 1) {
                this.$refs.pcsicalForm1.validate((valid) => {
                    if (valid) {
                        var data = {
                            email: this.User.email
                        }
                        this.checkloading = true
                        this.axios.post('/check-user', data)
                            .then(res => {
                                if (res.data.success) {
                                    this.step = 2
                                } else {
                                    this.mailError = true
                                }
                            })
                            .catch(() => {
                                return 2
                            })
                            .finally(() => {
                                this.checkloading = false
                            })
                    }
                })
            }

        },

    }
}
</script>

<style lang="scss">
.register {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background: var(--bg-top);
    width: 100%;

}

.opacity8 {
    opacity: 0.7;

}

.register-item {
    padding: 200px 100px 100px 100px;
    position: relative;
    z-index: 1;
}

.register-item:nth-child(1) {
    background: var(--white-bg);
    width: 55%;
}

.register-item:nth-child(2) {
    width: 45%;
}

.register-cover {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 100%;
    }
}

.register-title {
    padding: 0px 20px;

    h1 {
        font-family: Stem-Medium;
        font-size: 51px;
        line-height: 60px;
        color: var(--other-black);
        text-align: center;
    }

    p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        color: var(--white-black);
        margin-top: 20px;
        text-align: center;
    }
}

.register-card {
    width: 100%;
    max-width: 600px;
    padding: 0px;
    box-shadow: 0px 6px 16px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px rgba(0, 72, 153, 0.03);
    background: var(--white-bg);
    border-radius: 10px;
    margin-top: 60px;
    overflow: hidden;

    .el-input__inner {
        border: none;
        border-bottom: 1.5px solid #DDDFE2;
        padding: 10px 20px 20px 20px;
        height: auto;
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: var(--white-black);
        border-radius: 0;
        width: 100%;

    }

    .el-date-editor.el-input {
        width: 100%;
    }

    .no-icon {
        .el-input__prefix {
            display: none !important;
        }
    }

    .register-card-input {
        margin-bottom: 0px;
    }

    .register-card-header {
        width: 100%;
        display: flex;

        .register-card-header-item {
            width: 50%;
            padding: 30px 20px;
            background: #5CD9FF;
            cursor: pointer;

            &.active {
                background: #00B5EC;
            }

            p {
                font-family: Stem-Medium;
                font-size: 20px;
                line-height: 1.5rem;
                color: var(--white-color);
                text-align: center;
            }
        }
    }

    .register-card-form {
        padding: 40px 50px;
    }

    .register-submit {
        padding-top: 50px;
    }

}

.legal-first-inputs {
    display: flex;
    align-items: center;

    .register-card-input:nth-child(1) {
        padding-right: 10px;
    }

    .register-card-input:nth-child(2) {
        padding-left: 10px;
    }

}

.register-bottom-text {
    padding: 60px 20px 0px 20px;
    a{
        text-decoration: none;
    }
    p {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem;
        text-align: center;

        color: var(--white-black);

    }
}

.el-form-item.is-error .el-input__inner {
    color: #FF0000;
}

.el-form-item__error {
    font-family: Stem-Regular;
    font-size: 0.875rem;
    line-height: 1rem;
}

.register-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    p {
        font-family: Stem-Medium;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--other-black);
        margin-bottom: 40px;
        text-align: center;
    }
}

.password-input {
    position: relative;

    .show-password {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            cursor: pointer;
        }
    }

}

.register-clouds {
    position: absolute;
    top: 100px;
    right: 0px;
    z-index: 0;
    width: 100%;
    padding: 100px;

    img {
        width: 100%;
    }
}

.black-check {
    margin-top: 20px;
    display: flex;
    align-items: center;

    p {
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        margin-left: 10px;
        color: var(--white-black);
    }
}

@media screen and(max-width: 1200px) {
    .register-card {
        padding: 0px;
    }
}

@media screen and(max-width: 1130px) {
    .register-card {
        padding: 0px;
    }

    .register-item {
        padding: 200px 20px;
    }

}

@media screen and(max-width: 768px) {
    .register-item:nth-child(1) {
        width: 100%;
        background: var(--bg-top);
        padding: 150px 20px 20px 20px;
    }

    .black-check {
        p {
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }

    .register-item:nth-child(2) {
        width: 100%;
        padding: 50px 20px;
    }

    .register-cover {

        img {
            width: 50%;
        }
    }

    .register-title {
        h1 {
            font-size: 24px;
            line-height: 28px;
        }

        p {
            font-size: 0.875rem;
            line-height: 1rem;
            margin-top: 10px;
        }
    }

    .register-card {
        max-width: 450px;
        margin-top: 40px;
        padding: 0px 0px;

        .el-input__inner {
            padding: 0px 18px 18px 18px;
            font-size: 1rem;
            line-height: 1.125rem;

        }

        .register-card-header {
            width: 100%;
            display: flex;

            .register-card-header-item {
                padding: 20px 20px;

                p {
                    font-size: 1rem;
                    line-height: 1.125rem;
                }
            }
        }

        .register-card-form {
            padding: 30px 30px;
        }
    }

    .legal-first-inputs {
        flex-wrap: wrap;

        .register-card-input:nth-child(1) {
            padding-right: 0px;
            width: 100%;
        }

        .register-card-input:nth-child(2) {
            padding-left: 0px;
            width: 100%;
        }

    }

}

@media screen and(max-width: 500px) {
    .register-item:nth-child(1) {
        width: 100%;
        padding: 150px 0px 90px 0px;
    }

    .register-item:nth-child(2) {
        width: 100%;
        padding: 0px 20px 100px 20px;
    }

    .register-cover {

        img {
            width: 50%;
        }
    }
}
</style>
